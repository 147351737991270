import React from 'react'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import CardSection from '../layout/CardSection'
import { Link } from 'gatsby'
import { Box, Heading, Text, Container } from 'theme-ui'
import Breadcrumbs from '../components/Breadcrumbs/PageCrumbs'

export default () => {
	const title = 'Frequently Asked Questions'
	const styles = {
    flex: {
      display: 'flex'
    },
    running: {
      fontSize: `1.25em`,
    }
  }
  return (
    <>
      <Seo
        title='Frequently Asked Questions'
        description='From pricing, to samples, to MOQ, we get a lot of questions here at Pacific Beauty about our private label skincare program.  Read the most common FAQs below and get in touch with us if your question is not covered.'
      />
	  <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs title={title} />
      </Container>
      <Divider space={2} />
      <Stack>
        <Main>
          <PageTitle header='Frequently Asked Questions' />
          <CardSection>
            <Box>
              <Heading variant='h2'>May I have samples?</Heading>
              <Text sx={styles.running}>
                Yes. Please <Link to='/contact/'>contact us</Link> about getting
                samples delivered to you.
              </Text>
            </Box>
            <Divider space={3} />
            <Box>
              <Heading variant='h2'>
                What is your MOQ (minimum order quantity)?
              </Heading>
              <Text sx={styles.running}>
                Our MOQ is 48 units per sku. However, new customers are subject
                to a $10,000 minimum on their first order. Subsequent orders are
                only subject to the 48 units per sku MOQ.
              </Text>
            </Box>
            <Divider space={3} />
            <Box>
              <Heading variant='h2'>Do you have a catalog?</Heading>
              <Text sx={styles.running}>
                Yes. Please <Link to='/contact/'>contact us</Link> and we'll
                send you a catalog.
              </Text>
            </Box>
            <Divider space={3} />
            <Box>
              <Heading variant='h2'>
                Are your products tested on animals?
              </Heading>
              <Text sx={styles.running}>
                No. All of our products are cruelty-free. They are tested on
                people, not animals.
              </Text>
            </Box>
            <Divider space={3} />
            <Box>
              <Heading variant='h2'>Where are your products made?</Heading>
              <Text sx={styles.running}>
                Our products are manufactured in a 65,000 sq. ft. facility in
                beautiful Boca Raton, Florida USA.
              </Text>
            </Box>
            <Divider space={3} />
            <Box>
              <Heading variant='h2'>
                Can you help with customs documentation?
              </Heading>
              <Text sx={styles.running}>
                Absolutely. We ship all over the world and are well versed in
                what documents are required to get our skincare products through
                customs and properly registered. Some of the customs documents
                we routinely provide are:
                <ul>
                  <li>Certificate of Manufacture</li>
                  <li>Certificate of Free Sale</li>
                  <li>BSE Certificates</li>
                  <li>GMP Certificates</li>
                  <li>Certificate of Analysis</li>
                  <li>MSDS (Material Safety Data Sheet)</li>
                  <li>Returned and Salvaged Products</li>
                </ul>
              </Text>
            </Box>
            <Divider space={3} />
            <Box>
              <Heading variant='h2'>What are your prices?</Heading>
              <Text sx={styles.running}>
                We don't publish our prices publicly but if you{' '}
                <Link to='/contact/'>contact us</Link>, we'll send you our
                catalog which includes pricing.
              </Text>
            </Box>
            <Divider space={3} />
            <Box>
              <Heading variant='h2'>
                Is your manufacturing facility registered with the FDA?
              </Heading>
              <Text sx={styles.running}>
                Yes. Our 65,000 sq. ft. facility is ISO 22716 Certified, cGMP
                Accredited and FDA Registered.
              </Text>
            </Box>
          </CardSection>
        </Main>
      </Stack>
    </>
  )
}
